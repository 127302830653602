import React from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from "gatsby"
import AnimatedBackground from "./AnimatedBackground";
import Clock from 'react-live-clock';
import {Helmet} from "react-helmet";

import Button from '@material-ui/core/Button';

import '../styles/styles.scss';
import NavBar from '../components/NavBar';
import LandingPage from '../components/LandingPage';


const useStyles = makeStyles(theme => ({
  
  container: {
    height: "100%",
  },

  footer: {
    textAlign: 'center',
    fontFamily: ['Raleway','Sans Serif'],
    
    direction:'column',
    justify:'center',
    alignItems:'center',
    position: '-webkit-sticky',
    position: 'sticky',
  }
}));


export default () => {
  const classes = useStyles();
  return (
    <>
    <Grid container direction='column' className={classes.container}>
    <Helmet>
                <meta charSet="utf-8" />
                <title>MN - Home</title>
                <link rel="canonical" href="http://malvinnathan.com" />
            </Helmet>
      <NavBar />
      <LandingPage />  
      
      
    </Grid>
   
   </>
  )
}
