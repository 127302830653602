import React from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from "gatsby"
import AnimatedBackground from "../pages/AnimatedBackground";
import Clock from 'react-live-clock';

import Button from '@material-ui/core/Button';
import '../styles/styles.scss';
import AboutMe from '../pages/AboutMe';
import { FormatBold } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  
    title: {
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      color: "white",
      display: "flex",
    },
    titleBackground: {
      position: 'relative',
      width: '100%',
      height: '100vh',
    },
    fakeTop: {
      height: 850,
      position: 'relative',
      zIndex: -1,
      [theme.breakpoints.down('sm')]: {
        height: 700,
      },
      background: "yellow",
    },
    gridTop: {
      width: '100%',
      height: '100vh',
      position: 'relative',
      padding: 0,
      margin: 0,
      
      
    },
    gridBottom: {
      position: 'relative',
    },
    container: {
      height: "100%",
    },
    infoBox: {
      paddingLeft: 5,
      paddingRight: 5,
      marginTop: 20,
    },
    infoBoxHeadingContainer: {
      display: 'block',
    },
    icons: {
      height: 175,
      width: 175,
      margin: 4,
      color: '#3e4360',
    },
    fontstyle: {
      fontFamily: ["Montserrat", "cursive"],
      fontSize: 40,
      fontWeight: 'bold',
    },
    fontstyleaboutme: {
      fontFamily: ["'Short Stack'", "cursive"],
      marginTop: 80,
      fontSize: 30,
      marginLeft: 20,
    },
    fontstyleparagraph: {
      fontFamily: ['Raleway','Sans Serif'],
      fontSize: 30,
      width: 1000,
      textAlign: 'left',
      paddingLeft: '200px',
      margin: "50px",
    },
    button: {
      fontFamily: ["'Short Stack'", "cursive"],
      marginTop: 20,
      margin: 10,
      width: 300,
    },
    clock: {
      
      fontFamily: ["'Short Stack'", "cursive"],
      [theme.breakpoints.down('sm')]: {
        fontSize: "50px"
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: "100px"
      },
    },
  
  }));

export default function LandingPage() {
    const classes = useStyles()

    return (
       <>
        <Grid item className={classes.gridTop}>
        <div className={classes.titleBackground}>
          <AnimatedBackground />
        </div>
        <div className={classes.title}>

          <Grid container direction='column' justify='center' alignItems='center'>
        
            <h1 className={classes.clock}>
                    <Clock format="HH:mm:ss" interval={1000} ticking={true} />
            </h1>
           
            <Grid item>
              <Typography className={classes.fontstyle} align='center' variant='h1'>
                Hello, World!
              </Typography>
            </Grid>
            
            <Grid item>
              <Button className={classes.button} component={Link} to='#about' variant='outlined' color='inherit'>
                Find out more About Me
              </Button>
             </Grid>
          </Grid>
        </div>
          
      </Grid>
      <div id="about">
            < AboutMe/> 
      </div>
      
      </>
      
      
      
  

    

    )
}




